 /* for mobile */

.custom-mobile-appointment-widget-select-box.ant-select-single .ant-select-selector .ant-select-selection-item {
  font-size: 18px !important;
}

 .custom-mobile-appointment-widget-select-box .ant-select {
  border-radius: 8px !important;
  border-color: #D0D6E1 !important;
  background: #fff !important;
  height: 40px !important;
  min-height: 40px !important;
  align-items: center !important;
  font-size: 18px !important;
}

.custom-mobile-appointment-widget-select-box .ant-select-selector,
.custom-mobile-appointment-widget-select-box .ant-select-selector:hover,
.custom-mobile-appointment-widget-select-box.ant-select-focused .ant-select-selector {
  background-color: #fff !important;
  box-shadow: none !important;
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
  border-radius: 8px !important;
  border: 0.5px solid #8a94a8 !important;
  color: #3A485F;
  font-size: 18px !important;
}

.custom-mobile-appointment-widget-select-box .ant-select-dropdown {
  border-radius: 8px !important;
  background-color: #fff !important;
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
  font-size: 18px !important;
}
.custom-mobile-appointment-widget-select-box.ant-select-open .ant-select-selector {
  background-color: #fff !important;
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
}

.custom-mobile-appointment-widget-select-box-dropdown .ant-select-item-option-content{
  font-size: 16px !important;
}
