.ant-picker-content .ant-picker-cell::before {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
  width: 100%;
  height: 100%;
  background: none;
}


.slot-day {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  width: 35px;
  height: 34px;
  background: #ffffff;
  border-radius: 18px;
  flex: none;
  order: 6;
  flex-grow: 1;
}

@media (max-width: 991px) {
  .slot-day {
    width: 36px;
    height: 36px;
  }
}

.slots ::-webkit-scrollbar-thumb {
  background-color: #825ac7 !important;
}

/* @media screen  and (min-width: 750px) {
  .slots {
    height: 354px;
    overflow: scroll;
  }  
} */


.custom-antd-input-box.ant-input,
.custom-antd-input-box.ant-picker,
.custom-antd-input-box.ant-input:hover,
.custom-antd-input-box.ant-picker:hover,
.custom-antd-input-box.ant-input:focus,
.custom-antd-input-box.ant-picker-focused {
  box-shadow: none !important;
  border: 0.5px solid #8a94a8 !important;
  border-radius: 4px !important;
}

.custom-antd-input-box.ant-picker.ant-picker-disabled {
  background-color: #f5f5f5 !important;
}

.custom-antd-input-box.ant-input::placeholder,
.custom-antd-input-box.ant-picker input::placeholder,
.custom-select-box .ant-select-selector .ant-select-selection-placeholder {
  color: #8a94a8 !important;
  font-size: 14 !important;
  font-weight: 400 !important;
}

.custom-select-box .ant-select-selector,
.custom-select-box .ant-select-selector:hover,
.custom-select-box.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  min-height: 32px !important;
  height: 32px !important;
  border-radius: 4px !important;
  border: 0.5px solid #8a94a8 !important;
}

.custom-select-box-dropdown.ant-select-dropdown {
  padding: 8px;
  border-radius: 8px !important;
  border: 0.5px solid #e9ecf1;
  box-shadow: 0px 12px 60px -15px #0000002f;
}

.custom-select-box-dropdown.ant-select-dropdown
  .ant-select-item.ant-select-item-option {
  border-radius: 4px !important;
  padding: 5px !important;
  padding-left: 8px !important;
}

.hide {
  display: none;
}


.custom-collapse.ant-collapse {
  width: 100% !important;
  border: 0px !important;
}

.custom-collapse.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #e9ecf1 !important;
  border-radius: 0px !important;
}

.custom-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-content {
  padding: 0px 10px !important;
}

.custom-collapse.ant-collapse .ant-collapse-header {
  align-items: center !important;
}

.custom-collapse.ant-collapse .ant-collapse-header-text {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16.8px !important;
  text-align: left !important;
  color: #3A485F !important;
}

