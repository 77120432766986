.dragging-overlay {
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px dashed rgb(130, 90, 199);
  border-radius: 4px;
  padding: 16px;
  min-width: 200px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 16px;
}

.dragging-overlay__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dragging-overlay__label {
  margin: 0;
  font-size: 14px;
  color: #333;
  font-weight: 500;
}
