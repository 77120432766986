.conversation-messaging > .ql-container.ql-snow {
  height: 180px;
}

.conversation-messaging > .ql-toolbar.ql-snow {
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}

.common-form-container .conversation-messaging > .ql-toolbar.ql-snow {
  border-top: 0.5px solid #D0D6E1;
}

.day-op .conversation-messaging > .ql-toolbar.ql-snow {
  border-top: 0.5px solid #D0D6E1;
  border-bottom: none;
  /* padding-left: 40px; */
}

.common-form-container .conversation-messaging > .ql-container.ql-snow,
.day-op .conversation-messaging > .ql-container.ql-snow {
  height: 80px;
  flex-grow: 1;
}

.common-form-container .rich-text-editor,
.day-op .rich-text-editor {
  display: flex;
  flex-direction: column-reverse;
}

.patient-notes-editor-header {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 0.5px solid #D0D6E1;
  padding: 6px 8px;
}

.day-op.custom-select-box .ant-select-selector,
.day-op.custom-select-box .ant-select-selector:hover,
.day-op.custom-select-box.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #D0D6E1!important;
}

.day-op.custom-antd-input-box.ant-picker,
.day-op.custom-antd-input-box.ant-picker:hover,
.day-op.custom-antd-input-box.ant-picker-focused {
  border: 0.5px solid #D0D6E1 !important;
}

.cm-outreach.custom-select-box .ant-select-selector,
.cm-outreach.custom-select-box .ant-select-selector:hover,
.cm-outreach.custom-select-box.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #D0D6E1!important;
}
