.marvel-device .screen {
  overflow-y: scroll;
}
.marvel-device.macbook .screen {
  min-height: 600px;
}
.marvel-device.ipad .screen {
  min-height: 700px;
}
.marvel-device.iphone-x .screen {
  min-height: 740px;
}

// Overriding all bootstrap classes for mobile preview
// Setting width to 100%
.fh-phone-preview {
  %grid-column {
    flex: 0 0 100%;
    max-width: 100%;
  }

  $columns: 12;
  $infixes: sm, md, lg, xl, xxl;

  @each $infix in $infixes {
    @for $i from 1 through $columns {
      .col-#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }
  }
}
