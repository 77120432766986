.drag-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.drag-overlay__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  min-height: 120px;
  border-radius: 0.5rem;
  border: 1px dashed #e0e0e0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: rgb(var(--gray-1));
  overflow: hidden;
}

.drag-overlay.drag-over .drag-overlay__container {
  border: 1px dashed rgb(130, 90, 199);
  background-color: rgba(127, 86, 217, 0.13);
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-10%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.drag-overlay__title {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(var(--gray-12));
  text-align: center;
}
