.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}
.flex {
  display: flex;
}
.flex-grow {
  flex-grow: 1;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.font-bold {
  font-weight: bold;
}
.font-normal {
  font-weight: normal;
}
.overflow-y-auto {
  overflow-y: auto;
}
.absolute {
  position: absolute;
}
.h-1\/2 {
  height: 50%;
}
.h-1\/3 {
  height: 33.33%;
}
.h-32px {
  height: 32px;
}
.bottom-0 {
  bottom: 0;
}
.relative {
  position: relative;
}
.flex-col {
  flex-direction: column;
}
.opacity-30 {
  opacity: 0.3;
}
.element-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 8px;
  /* transition: all 0.2s ease; */
}
.element-wrapper-main-container {
  border-radius: 8px;
  color: var(--foreground);
}

.element-wrapper--active {
  border: 1px solid #b895ec;
}

.element-actions {
  position: absolute;
  right: 6px;
  top: 6px;
  display: flex;
  gap: 8px;
  z-index: 10;
  animation: fadeIn 0.2s ease;
}
.form-renderer .element-actions {
  right: 8px;
  top: 8px;
}

.element-action-button {
  padding: 4px;
  background: transparent;
  transition: background-color 0.2s ease;
  border: 1px solid #e5e5e5;
  border-radius: 18px;
  width: 28px;
  height: 28px;
  overflow: hidden;
  background: rgb(var(--gray-1));
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-renderer .element-action-button {
  padding: 0px;
  background: transparent;
  border: none;
  width: 28px;
  height: 28px;
  overflow: hidden;
  margin-top: 2px;
}

.care-plan-styles .container-field {
  background-color: rgb(246, 247, 248) !important;
  border: 0px !important;
  border-radius: 12px !important;
}
.note-preview-styles .container-field {
  background-color: #fff !important;
  border: 1px solid black;
  border-radius: 12px !important;
}

.care-plan-styles .container-field-header {
  background-color: transparent !important;
  border-bottom: 0px !important;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  align-items: center;
 }
 .custom-form-styles .container-field-header .field-label,
 .care-plan-styles .container-field-header .field-label {
  font-weight: 700;
  font-size: medium !important;
 }
 .note-preview-styles .container-field-header .field-label {
  font-weight: 800;
  font-size: 18px !important;
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: none;
  color: #000;
 }
 .note-preview-styles .field-label {
  font-weight: 600;
  font-size: 18px !important;
  margin-bottom: 6px;
  padding-bottom: 8px;
  border-bottom: 1px solid black;
  width: 100%;
  color: #000;
 }
 .note-preview-styles .file-table th,
 .note-preview-styles .file-table td,
 .note-preview-styles .field-value {
  font-weight: 400;
  font-size: 14px;
  color: black;
 }

 .care-plan-styles .container-field-body {
  padding: 0.5rem 1rem 0 1rem !important;
}

.note-preview-styles .container-field-header {
  background-color: #fff;
  padding: 16px;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid black;
  overflow: hidden;
}

.element-action-icon {
  height: 16px;
  width: 16px;
  color: #a855f7; /* purple-500 */
}

/* Optional animation for the actions appearing */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* @keyframes fadeIn1 {
  from {
    opacity: 0;
    transform: translateY(-0px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} */

.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.padding-4 {
  padding: 16px !important;
}
#custom-form-builder-drop-area {
  overflow-y: auto;
  height: 83vh;
}
.flex-4 {
  flex: 4;
}
.flex-7 {
  flex: 7;
}
.max-w-20vw {
  max-width: 20vw;
}
.library-element-card {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  overflow: hidden;
}
.border-radius-8 {
  border-radius: 8px;
}
#edit-configuration-view {
  border-left: .5px solid #dedede;
  height: calc(100vh - 165px);
}
.edit-configuration-view-title,
.edit-configuration-view-content {
  padding: 20px 20px 0px 20px;
}
.container-field {
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
}
.container-field-header {
  background-color: #f0f0f0;
  padding: 16px;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #e5e5e5;
  overflow: hidden;
}
.nested-container {
  border: 1px dashed #e5e5e5;
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 76px !important;
}
.min-h-100px {
  min-height: 100px;
}
.min-h-64px {
  min-height: 64px;
}
.column-container {
  border: 1px dashed #cbd5e0;
  border-radius: 0.25rem;
  padding: 0.5rem;
  min-height: 100px;
}
.form-renderer .column-container {
  border: none;
  padding: 0px !important;
  min-height: 0px;
}
.nested-column {
  background-color: #ffffff;
  border-radius: 0.25rem;
  min-height: 64px;
  padding-bottom: 76px !important;
}
.required-asterisk {
  color: #D92D20;
  margin-top: -4px;
  margin-left: 4px;
  font-size: 12px;
}
.field-label {
  color: var(--Colors-Grey-400, #3A485F);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.field-label-250 {
  color: var(--Colors-Grey-400, #8a94a8);
}
.align-flex-start {
  align-items: flex-start;
}
.gap-4 {
  gap: 16px;
}
.gap-2 {
  gap: 8px;
}
.gap-1 {
  gap: 4px;
}
.form-renderer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.padding-bottom-16 {
  padding-bottom: 16px;
}
.w-400 {
  width: 400px;
}
.field-description {
  color: #6F7A90;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: -16px;
}
.edit-configuration-view-title {
  color: var(--Colors-Grey-400, #3A485F);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  border-bottom: 1px solid #e5e5e5;
  justify-content: center;
}
.text-gray-500 {
  color: #6F7A90;
}
.text-sm {
  font-size: 12px;
}
.form-input-field {
  border: 0.5px solid #8A94A8 !important;
  border-radius: 4px !important;
  height: 32px !important;
  box-shadow: none !important;
}
.form-input-field:focus {
  border-color: #b895ec !important;
  box-shadow: none !important;
  border-right-width: 1px !important;
  outline: 0 !important;
}
.form-renderer .form-input-field.ant-input-status-error {
  border-color: #D92D20 !important;
}
.form-renderer .custom-text-input-error {
  border-color: #D92D20 !important;
}
.common-form-container .error-input-field {
  border: 0.5px solid #D92D20;
  border-radius: 4px;
  height: 32px;
}
.common-form-container .error-input-field:focus {
  border-color: #D92D20;
  border-right-width: 1px;
  outline: 0;
}
.readonly-label {
  font-weight: 700;
  font-size: 16px;
}
.error-message {
  color: #D92D20;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.mb-0\.5 {
  margin-bottom: 2px;
}
.error-field {
  border-color: #D92D20 !important;
}
.common-form-container .ant-picker-status-error.ant-picker {
  border-color: #D92D20 !important;
}
.ml-0\.5 {
  margin-left: 2px;
}
.inline-flex {
  display: inline-flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-end {
  align-items: flex-end;
}
.items-start {
  align-items: flex-start;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.form-renderer .ant-checkbox-wrapper + .ant-checkbox-wrapper,
.custom-form-builder .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px !important;
}
.padding-horizontal-11px {
  padding-left: 11px !important;
  padding-right: 11px !important;
}
.h-32 {
  height: 32px;
}
.form-renderer .ql-snow .ql-picker.ql-expanded .ql-picker-options,
.custom-form-builder .ql-snow .ql-picker.ql-expanded .ql-picker-options {
  bottom: 100% !important;
  top: auto !important;
}
.form-renderer .ant-input-number,
.custom-form-builder .ant-input-number {
  width: 100%;
}
.file-upload-dragger {
  background: #ffffff;
  border: 2px dashed #e2e8f0;
  border-radius: 8px;
}

.file-upload-dragger:hover {
  border-color: #6366f1;
}

.file-upload-dragger .ant-upload-drag-icon {
  color: #6366f1;
  font-size: 48px;
  margin-bottom: 8px;
}

.file-upload-dragger .ant-upload-text {
  color: #1f2937;
  font-size: 16px;
  margin-bottom: 4px;
}

.file-upload-dragger .ant-upload-hint {
  color: #6b7280;
  font-size: 14px;
}

.file-upload-dragger .ant-upload-list {
  max-height: 300px;
  overflow-y: auto;
  margin-top: 16px;
}

.file-upload-dragger .ant-upload-list-item {
  padding: 8px;
  border-radius: 4px;
  margin-top: 8px;
}

.file-upload-dragger .ant-upload-list-item-progress {
  padding: 0 8px;
  margin-top: 4px;
}

.file-upload-dragger .ant-upload-list-item-name {
  color: #1f2937;
  font-size: 14px;
}

.file-upload-dragger .ant-upload-list-item-card-actions {
  position: relative;
  top: -2px;
}

.file-table-container {
  margin-top: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  overflow: hidden;
}

.file-table {
  width: 100%;
  border-collapse: collapse;
}

.file-table th {
  background-color: #f9fafb;
  padding: 0.75rem 1rem;
  text-align: left;
  font-weight: 500;
  color: #6b7280;
  border-bottom: 1px solid #e5e7eb;
}

.file-table-row {
  border-bottom: 1px solid #e5e7eb;
}

.file-table-row:last-child {
  border-bottom: none;
}

.file-name-cell {
  padding: 1rem;
  width: 65%;
}

.file-size-cell {
  padding: 1rem;
  width: 20%;
  color: #6b7280;
  font-size: 0.875rem;
}

.file-actions-cell {
  padding: 1rem;
  width: 15%;
  display: flex;
  justify-content: space-between;
  gap: 16x;
}

.file-name-text {
  display: block;
  font-weight: 500;
}

.progress-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.25rem;
}

.progress-text {
  font-size: 0.75rem;
  color: #6b7280;
  min-width: 3rem;
  text-align: right;
}

.error-text {
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
.no-files-attached {
  background-color: #f9fafb;
  padding: 1rem;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e7eb;
}

.signature-container {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  overflow: hidden;
  background: white;
}

.signature-pad-wrapper {
  border-bottom: 1px solid #e5e7eb;
  height: 200px !important;
  margin: 0 auto;
}

.signature-pad {
  background-color: #ffffff;
  width: 100% !important;
  height: 100% !important;
}

.signature-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 0.75rem;
  background-color: #f9fafb;
}

.signature-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s;
}

.signature-button.undo {
  background-color: #f3f4f6;
  color: #1f2937;
}

.signature-button.undo:hover {
  background-color: #e5e7eb;
}

.signature-button.clear {
  background-color: #fee2e2;
  color: #ef4444;
}

.signature-button.clear:hover {
  background-color: #fecaca;
}

.signature-preview {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.signature-preview img {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.no-signature {
  padding: 2rem;
  text-align: center;
  color: #6b7280;
  background-color: #f9fafb;
  font-size: 0.875rem;
}
.footer-label {
  color: #6b7280;
  font-size: 0.875rem;
  text-align: center;
  width: 100%;
}
.top-2 {
  top: 8px;
}
.right-2 {
  right: 8px;
}
.left-2 {
  left: 8px;
}
.z-10 {
  z-index: 10;
}

.table-container {
  border-radius: 8px;
  overflow: hidden;
  margin-top: 0.5rem;
  border: none;
}

.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
}

.custom-table th,
.custom-table td {
  border: 1px solid #e5e7eb;
  padding: 0.75rem 1rem;
}

/* Remove double borders */
.custom-table th:not(:last-child),
.custom-table td:not(:last-child) {
  border-right: none;
}

/* Keep horizontal borders between rows */
.custom-table tbody tr:not(:last-child) td {
  border-bottom-width: 1px;
}

.custom-table thead tr th {
  border-bottom-width: 1px;
}

/* Border radius */
.custom-table thead tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

.custom-table thead tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

.custom-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.custom-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

/* Remove duplicate borders */
.custom-table tbody tr td {
  border-top: none;
}

.question-header {
  width: 40%;
  background-color: #f9fafb;
  font-weight: 500;
  color: #6b7280;
  text-align: left;
}

.option-header {
  background-color: #f9fafb;
  font-weight: 500;
  color: #6b7280;
  text-align: center;
}

.question-cell {
  color: #1f2937;
  font-weight: 400;
}

.option-cell {
  text-align: center;
  vertical-align: middle;
}

.table-row:hover {
  background-color: #f9fafb;
}

.readonly-table {
  table-layout: fixed;
}

.readonly-table .question-header,
.readonly-table .answer-header {
  width: 50%;
  background-color: #f9fafb;
  font-weight: 500;
  color: #6b7280;
  text-align: left;
  padding: 0.75rem 1rem;
}

.readonly-table .question-cell {
  color: #1f2937;
  font-weight: 400;
  padding: 0.75rem 1rem;
  vertical-align: top;
}

.readonly-table .answer-cell {
  color: #4b5563;
  padding: 0.75rem 1rem;
  vertical-align: top;
}

.readonly-table .table-row:hover {
  background-color: #f9fafb;
}
.right-border {
  border-right: 0.5px solid #e5e7eb;
}
.margin-bottom-16 {
  margin-bottom: 16px;
}
.ant-radio-checked .ant-radio-inner{
  border-color: #825ac7 !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: #825ac7;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #825ac7 ;
}
.justify-left {
  justify-content: flex-start;
}
.custom-form-segmented .ant-segmented-item {
  transition: #f6f7f8 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.custom-form-segmented .ant-segmented-item-selected {
  background-color: #f6f7f8;
  border-radius: 4px;
  border: 0.5px solid #e5e5e5;
}
.custom-form-segmented .ant-segmented-thumb {
  background-color: #f6f7f8;
}
.form-renderer .preview-mode .ant-radio-disabled + span {
  color: #000;
}
.w-\[20vw\] {
  width: 20vw;
}

.min-w-0 {
  min-width: 0;
}
.flex-none {
  flex: none;
}
.form-renderer .field-description {
  margin-top: -8px;
  margin-bottom: 0px;
}
