.form-image-component .ant-upload.ant-upload-select-picture-card,
.form-image-component .ant-upload-list-picture-card-container {
  width: 100%;
}

.form-image-container .overlay {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
}
.form-image-container .overlay div {
  position: relative;
  display: inline-block;
  top: 40%;
  left: 35%;
  margin: auto;
}
.form-image-container:hover .overlay {
  display: block;
  background-color: rgb(30, 28, 28);
  opacity: 0.6;
}
